const en = {
  global: {
    backToHome: 'Home',
    cancel: 'Cancel',
    close: 'Close',
    cold: 'cold',
    create: 'Create',
    delete: 'Delete',
    edit: 'Edit',
    export: 'Export',
    finish: 'Finish',
    heating: 'heating',
    import: 'Import',
    no: 'No',
    remove: 'Remove',
    reset: 'Reset',
    save: 'Save',
    supportEmail: 'energymapper-support@efficacity.com',
    validate: 'Validate',
    warning: 'Warning',
    yes: 'Yes',
  },
  entityTypes: {
    AutoConsumptionZone: 'Autoconsumption zone',
    Building: 'building | buildings',
    DistrictLimit: 'perimeter | perimeters',
    network: 'Heating and/or cooling networks',
    District: 'District | Districts',
    source: 'source | sources',
    highConsumptionBuilding: 'High-consumption building',
    equivalentBuilding: 'Equivalent building',
  },
  entities: {
    building: {
      addCustomThermalLoad: 'Add electric load',
      create: 'Create building',
      dataOrigin: 'Building info source',
      dataOriginValues: {
        custom: 'Manual user import',
        imported: 'Automatic import from BDTOPO',
      },
      floorArea: 'Surface (m²)',
      floorCount: 'Number of floors',
      hasSolarPanels: 'Photovoltaic panels installed',
      load: 'Electric load',
      name: 'Name',
      purpose: 'Usage',
      purposeValues: {
        individual_housing: 'Individual housing',
        collective_housing: 'Collective housing',
        office: 'Office',
        school: 'School',
        shop: 'Shop',
      },
      year: 'Construction year',
    },
    autoConsumptionZone: {
      name: 'Name',
    },
    photovoltaicPanel: {
      albedo: 'Albedo',
      batteryCapacity: 'Battery capacity (kWh)',
      batteryInvestmentCost: 'Battery investment cost (€/kWh)',
      connectionLossesAndMissmatchCoeficient: 'Connection losses and missmatch coefficient',
      horizontalAngle: 'Angle with the horizon (°)',
      inverterEfficiency: 'Inverter efficiency',
      lowLightEfficiencyCorrection: 'Transmission absorption ratio',
      lowLightEfficiencyCorrectionTooltip: 'Modules solar transmission absorption ratio',
      nominalInverterPower: 'Nominal inverter power (W)',
      panelPeakPower: 'Peak power by pannel (W)',
      panelSurface: 'Pannel surface (m²)',
      select: 'Install solar panels',
      southAngle: 'Angle with the south (°)',
      studyCostInvestmentRatio: 'Ratio of CAPEX for project management cost',
      temperatureCoefficient: 'Peak power temperature coefficient (/°C)',
      temperatureCoefficientTooltip:
        'Peak power temperature coefficient as defined in CEI61215 and CEI61646',
      thermalConfinement: 'Thermal confinment degree',
      thermalConfinementTooltip: 'Thermal confinment degree of the back of modules',
      thermalEquilibriumTemperature: 'Thermal equilibrium temperature (°C)',
      thermalEquilibriumTemperatureTooltip:
        'Thermal equilibrium temperature of the module as defined in CEI61215 and CEI61646',
    },
    equivalentBuilding: {
      buildingAdded: 'Building added to the equivalent building {equivalentBuildingName}',
      equivalentSurface: 'Surface totale (m²)',
      modificationWarning:
        'Modified thermal loads will be applied to every building of the corresponding type.',
    },
    electricSubstation: {
      tooltip: 'Electric substation',
    },
    highConsumptionBuilding: {
      purposes: {
        collective_housing: 'Collective housing',
        commercial_center: 'Commercial center',
        hospital: 'Hospital',
        swimming_pool: 'Swimming pool',
      },
    },
  },
  units: {
    MWh_by_year: 'MWh/year',
    kW: 'kW',
  },
  sourceToDistrict: {
    distribution: {
      investmentByUnitLength: 'Cost of fitting per linear meter (€/m)',
      length: 'Distance source - buildings (m)',
    },
    exploitationConstraints: {
      askForStorage: 'Thermal storage option',
      askForStorageDetail:
        'Simulation of a variant with thermal storage dedicated to the source (i.e. we will have combinations with and without thermal storage for this source)',
      endDateSummer: 'Last day of summer',
      maxCoverageRateByYear: 'Maximum annual coverage rate',
      minCoverageRateByYear: 'Minimum annual coverage rate',
      minExploitedTimeByYear: 'Minimum annual operating hours',
      minLoadRate: 'Minimum hourly charge rate',
      minLoadRateDetail:
        'Minimum acceptable load rate for the generator (heat pump or boiler) to avoid on-off operation',
      minSuccessiveHours: 'Minimum successive operating hours',
      minSuccessiveHoursDetail:
        'Minimum number of successive hours that a source must deliver, avoiding mobilizing it for a few hours of operation in accordance with realistic control)',
      startDateSummer: 'First day of summer',
      summerOff: 'Operation interrupted in summer',
    },
    exploitationConstraintsUnit: {
      minLoadRate: 'mean load rate',
      minSuccessiveHours: 'average successive operating hours',
    },
    heatExchanger: {
      efficiency: 'Substation exchanger efficiency',
      investmentByKW: 'exchanger CAPEX (€/kW)',
      operatingRatio: 'Ratio of CAPEX for annual maintenance',
    },
    heatPump: {
      electricityCostByKWh: 'Electricity cost (€/kWh)',
      operatingRatio: 'Ratio of CAPEX for annual maintenance',
    },
    source: {
      biomass: {
        forestPotential: 'Total forest extractable potential (kWh/year)',
        woodDeliveryCost: 'Cost of transporting wood (€/(kWh.km))',
        woodDeliveryDistance: 'Average distance traveled by wood (m)',
        woodPurchaseCost: 'Cost of the woodchip (€/kWh)',
      },
      biomethane: {
        biomethanePotential: 'Biomethanation potential (GWh/year)',
      },
      coldStorage: {
        investmentByKW: 'CAPEX recovery exchanger (€/kW)',
        storageTemperature: {
          label: 'Type of cold',
          negative: 'Negative',
          positive: 'Positive',
          positive_negative: 'Positive-Negative',
        },
        volume: 'Storage volume (m3)',
      },
      datacenter: {
        certification: {
          label: 'Data Center Classification',
          tier_1: 'Tier I',
          tier_2: 'Tier II',
          tier_3: 'Tier III',
          tier_4: 'Tier IV',
        },
        coolingEfficiency: 'Cooling efficiency rating',
        surface: 'Data Center total area (m²)',
        surfaceIt: 'Server room area (m²)',
      },
      geothermalGroundwater: {
        depth: 'Borehole depth (m)',
        flowRate: 'Groundwater flow (m³/h)',
        investmentByDepth: 'CAPEX (€/meter of draw-off)',
      },
      geothermalProbe: {
        depth: 'Probe depth (m)',
        geothermalConductivity: 'Thermal conductivity (W/m)',
        investmentByDepth: 'CAPEX (€/meter of probe)',
      },
      industrialWasteHeat: {
        activityCodeIcpe: "ICPE code of the company's main process",
        activityCodeNaf: 'Main business process NAF code',
        powerHighTemperature: 'High temperature source power (W)',
        powerLowTemperature: 'Low temperature source power (W)',
      },
      methanation: {
        methanationPotential: 'Methanation potential (GWh/an)',
      },
      pyrogasification: {
        pyrogasificationPotential: 'Pyrogasification potential (GWh/an)',
      },
      sewageSanitationNetwork: {
        meanRate: 'Average wastewater flow (m³/day)',
      },
      sewageTreatmentPlant: {
        maxRate: 'Maximum inflow (m³/day)',
        maxSewageCapacity: 'Maximum capacity (m³)',
        meanRate: 'Average inflow (m³/day)',
        meanSewageCapacity: 'Average capacity (m³)',
      },
      solarThermal: {
        investmentByUnitArea: 'CAPEX (€/m² of panel)',
        panelsSizingMethod: {
          interseasonal: 'Interseasonal',
          label: 'Thermal storage sizing method',
          summer: 'Summer',
        },
      },
      wasteIncinerationPlant: {
        lowerHeatingValue: 'Average LCV of waste (MJ/kg)',
        weightByYear: 'Mass of waste treated (tons/year)',
      },
    },
    sourceToDistrict: {
      studyCostInvestmentRatio: 'Ratio of CAPEX for project management cost',
    },
    storage: {
      investmentByUnitVolume: 'CAPEX (€/m³ of storage)',
    },
  },
  errors: {
    unknown: 'An error occurred',
    get: {
      address: 'Error during address fetching',
      building: 'Error during building fetching',
      buildingImportZoneTooLarge: 'Building import zone too large, please select a smaller zone',
      customThermalLoad: 'Error during custom thermal load fetching',
      district: 'Error during district data fetching',
      electricSubstation: 'Error during electric substation fetching',
      highConsumptionBuildings: 'Error getting high consumption buildings data',
      importBuildings: 'Error during building data fetching',
      networks: 'Error during thermal networks fetching',
      primaryData: 'Error during data fetching',
      project: 'Error during project fetching',
      simulations: 'Error during simulations fetching',
      source: 'Erreur during sources fetching',
      sourcesData: 'Error during sources data fetching',
      studyThermalLoad: 'Error during district needs fetching',
      thermalNetworks: 'Error getting thermal networks data',
    },
    create: {
      building: 'Error during building creation',
      customThermalLoad: 'Error during custom thermal load creation',
      district: 'Error during district limit creation',
      project: 'Error during project creation',
      simulation: 'Error during simulation launching',
      sourceToDistrict: 'Error during source creation',
      source: 'Error during sources creation',
      study: 'Error during study creation',
    },
    edit: {
      district: {
        'simulation in cooling mode without cooling needs':
          'Please uncheck the "Simulation to supply a cooling network" box for use without Cooling.',
        'simulation in heating mode without heating needs':
          'Please check the "Simulation to supply a cooling network" box for use with Cooling only.',
        unknown: 'Error during district edition',
      },
      project: {
        unknown: 'Error during project edition',
      },
      sourceToDistrict: {
        unknown: 'Error during source edition',
      },
      unknown: 'An error occurred during edition.',
    },
    delete: {
      building: 'Error during building deletion',
      customThermalLoad: 'Error during custom thermal load deletion',
      project: 'Error during project deletion',
      sourceToDistrict: 'Error during source deletion',
      source: 'Erreur during sources deletion',
      study: 'Error during study deletion',
    },
    input: {
      date: 'Please enter a valid date (mm-dd)',
      isBetween: 'Please enter a value between {min} and {max}',
      isFloatAbove: 'Please enter a value lower than {max}',
      isFloatBelow: 'Please enter a value greater than {min}',
      isFloatBetween: 'Please enter a value between {min} and {max}',
      positiveFloat: 'Please enter a positive number',
      positiveInteger: 'Please enter a positive integer',
      rate: 'Please enter a value between 0 and 1',
      required: 'Please fill this input',
    },
  },
  warnings: {
    delete: {
      project:
        'You are about to delete your project {name}. It will also delete all associated studies and simulation results. Are you sure?',
      study:
        'You are about to delete your study {name}. It will also delete all associated simulation results. Are you sure?',
    },
  },
  headband: {
    infos: 'If you encounter any problem, please contact us',
    contactUs: 'contact us',
    logout: 'Log out',
  },
  home: {
    addProject: '+ Add a project',
    openProject: 'Open the project',
    projectsList: 'Projects list ({nb})',
  },
  createEditProject: {
    addProject: 'Add a new project',
    editProject: 'Edit a project',
    projectInfos: 'Project informations',
    nameLabel: 'Project name',
    namePlaceholder: 'Fill a name',
    descriptionLabel: 'Project description',
    descriptionPlaceholder: 'Fill a description',
    localizationLabel: 'Project localization',
    localizationPlaceholder: 'Search for a place (town, address...)',
  },
  project: {
    project: '{name} project',
    addMasterPlan: '+ Add a master plan',
    addStudy: '+ Add a study',
    openAllTabs: 'Expand all',
    closeAllTabs: 'Collapse All',
    seeMasterPlan: 'Open master plan',
    seeStudy: 'Open study',
    resultNb: '0 result | 1 result | {nb} results',
    availableViews: 'Available view | Available views',
    resultBtn: {
      noResult: 'No result',
      success: 'Open result',
      pending: 'Simulation pending',
      failure: 'Error during simulation',
    },
    seeSimulations: 'See simulations',
  },
  study: {
    label: 'study',
    types: {
      thermal: 'Thermal',
      electric: 'Electric',
    },
  },
  simulationList: {
    title: 'List simulations',
    seeResults: 'See simulation results',
    simulation: 'simulation',
    simulationPending: 'Simulation still pending',
    simulationRunning: 'Simulation running',
    simulationFailed: 'Simulation failed',
    backToMap: 'Back to Map',
    backToStudies: 'Back to studies',
  },
  createEditStudy: {
    addStudy: 'Add a new study',
    descriptionLabel: 'Study description',
    descriptionPlaceholder: 'Enter a description',
    editStudy: 'Edit a study',
    nameLabel: 'Study name',
    namePlaceholder: 'Enter a name',
    studyInfos: 'Study informations',
    studyTypeLabel: 'StudyType',
    studyTypePlaceholder: 'Select a studyType',
  },
  mapPage: {
    mapLeftMenu: {
      categories: {
        AutoConsumptionZone: 'Autoconsumption zone',
        Building: 'Buildings',
        District: 'District perimeter',
        Source: 'Sources',
      },
      actions: {
        addNewDistrictPerimeter: 'Add new zone',
        drawNewBuilding: 'Draw custom building',
        drawNewDistrict: 'Create the perimeter',
        importBuildings: 'Import buildings',
        newBuilding: 'Add a new building',
        newZone: 'Add a new zone',
      },
    },
    mapRightMenu: {
      cancel: 'Cancel',
      delete: 'Delete',
      select: 'Select',
      selectMultiplePotentials: 'Select source potentials',
      update: 'Save',
      validate: 'Validate',
    },
    mapTiles: 'Map tiles:',
    mapLegend: 'Map legend:',
    sourceTypes: {
      biomass: 'Biomass',
      biomethane: 'Biomethanation',
      cold_storage: 'Cold storage',
      datacenter: 'Datacenter',
      geothermal_groundwater: 'Geothermal groundwater',
      geothermal_probe: 'Geothermal probe',
      industrial_waste_heat: 'Industrual waste heat',
      methanation: 'Methanation',
      pyrogasification: 'Pyrogasification',
      sewage_sanitation_network: 'Wastewater sanitation network',
      sewage_treatment_plant: 'Wastewater treatment plant',
      solar_thermal: 'Solar thermal',
      waste_incineration_plant: 'Waste incineration plant',
    },
    networkTypes: {
      coldNetwork: 'Cooling network',
      hotNetwork: 'Hot Network',
      coldNetworkPoint: 'Cooling network center',
      hotNetworkPoint: 'Heating network center',
    },
    listOf: {
      District: 'District',
      DistrictLimit: 'District perimeter',
      Building: 'List of buildings',
      source: 'List of available sources',
      network: 'Heating/cooling network',
    },
    possibleActions: 'Possible actions',
    drawPopinTitle: {
      DistrictLimit: 'Create the perimeter',
      Building: 'Create one or more buildings',
      AutoConsumptionZone: 'Create an autoconsumption zone',
    },
    drawPolygonOnMap: 'Draw a polygon on the map by placing markers',
    removeLastPoint: 'Remove the last point',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    simulationPopin: {
      continue: 'Continue',
      errors: {
        cases: {
          geoThermalProbeWithoutUse:
            'Geothermal probes require the presence of air conditioning to regenerate ' +
            'the requested deposit in winter by injecting into the probes the heat to be ' +
            'evacuated from the air conditioning in summer. Select air conditioning in ' +
            'district definition and then enter one or more buildings with non-zero cooling needs.',
          heatingSourcesForCoolingUse:
            'The following selected sources {sourcesNames} are not suitable for supplying a cooling network.',
          noPhotovoltaicPanels:
            'Equip at least one roof with photovoltaic panels in the zone {zones} before launching simulation.|Equip at least one roof with photovoltaic panels in the zones {zones} before launching simulation.',
          noSources: 'Select at least one renewable source.',
          noThermalLoadsNeeds: 'Enter one or more buildings with {type} needs.',
          thermalLoadsMissing:
            'No default thermal load, enter custom thermal load for building typologies {purposes}.',
        },
        description:
          'The following error was encountered:|The following errors were encountered:',
        title: 'Errors when launching the simulation',
      },
      launchSimulation: 'Launch the simulation',
      simulationConstraintsTitle:
        'You are about to launch a simulation with the following exploitation constraints :',
      title: 'You are about to launch a simulation with the following parameters: ',
    },
    importBuildingsPopin: {
      confirmation:
        'You are about to import buildings from BDTOPO in the district zone, are you sure?',
      importSummaryTitle: 'Imported Buildings',
      nothingImported: 'No new buildings were found.',
    },
    SimulationConstraintsTable: {
      constraintHeader: 'Constraint',
      title: 'Exploitation constraint',
    },
    SimulationParametersTable: {
      equivalentBuildingsTable: {
        title: 'Equivalent buildings',
        headers: {
          name: 'Typology',
          equivalentSurface: 'Floor area (m²)',
          purpose: 'Purpose',
          year: 'Year',
        },
        purpose: {
          individual_housing: 'Individual housing',
          collective_housing: 'Collective housing',
          office: 'Office',
          school: 'School',
          shop: 'Shop',
        },
      },
      districtTable: {
        title: 'District',
        headers: {
          cooling_temperature: 'Cooling temperature (°C)',
          heating_temperature: 'Heating temperature (°C)',
          simulationMode: 'Mode de simulation',
          totalCoolingLoad: 'Total cooling load (MWh/year)',
          totalHeatingLoad: 'Total heating load (MWh/year)',
          uses: 'Uses',
        },
        uses: {
          heating: 'Heating',
          cooling: 'Cooling',
          dhw: 'Domestic hot water',
        },
        simulationMode: {
          heating: 'Heating',
          cooling: 'Cooling',
        },
      },
      sourceToDistrictTable: {
        title: 'Selected sources',
        headers: {
          name: 'Name',
          sourceType: 'Source Type',
          askForStorage: 'Ask for storage',
        },
      },
    },
    CustomThermalLoad: {
      climateZone: 'Climate zone',
      climateZoneHint: 'The climate zone of the project will be used',
      createCustomThermalLoad: 'Create new thermal load',
      name: 'Name',
      title: 'New custom thermal load',
      unit: 'Unit',
      uploadFile: 'Thermal load in json format (array of 8760 values)',
      uploadFileFormat:
        'Specify the need of the building at the hourly time step over a year (i.e. 8760 values) in Watt or Watt per m²',
    },
  },
  mapRightMenu: {
    selectMultiplePotentials: {
      selectedPotential: 'Selected wood anual energetic potential (MWh/an): {potential}',
      districtNeeds: 'Annual Besoins de chaleur annuels (MWh/an): {needs}',
      selectedPotentialRatio: 'Or a ratio Potential / Needs of {ratio}',
    },
    toggleMenuBtn: {
      true: 'Close menu',
      false: 'Open menu',
    },
    multiSelectionPopinContent: 'Validate the actual multiple selection?',
    openVectorPopin: 'Open popin',
    launchSimulationBtn: 'Launch a simulation',
    measureBtn: 'Measure on map',
    districtNeeds: 'Total district needs',
    conditionNotMet: {
      noDistrictLoad: 'No district thermal load',
    },
    buildingForm: {
      addCustomThermalLoad: 'Add thermal load',
      customThermalLoad: {
        heating: 'Custom heating thermal Load',
        cooling: 'Custom cooling thermal Load',
        dhw: 'Custom domestic hot waterthermal Load',
      },
      defaultThermalLoad: 'Default thermal load',
      delete: 'Delete',
      deleteCustomThermalLoad: 'Delete thermal load ',
      name: 'Name',
      nonMatchingThermalLoad: 'Non matching thermal loads',
      purpose: {
        label: 'Purpose',
        choices: {
          individual_housing: 'Individual housing',
          collective_housing: 'Collective housing',
          office: 'Office',
          school: 'School',
          shop: 'Shop',
        },
      },
      submit: 'Save',
      year: 'Construction year',
    },
    districtForm: {
      cooling_mode: 'Simulate cooling needs',
      cooling_temperature: 'Cooling temperature',
      title: 'District parameters',
      heating_temperature: {
        label: 'Heating temperature of the district',
        value: '{temperature}°C',
      },
      uses: {
        choices: {
          cooling: 'Cooling',
          dhw: 'Domestic hot water',
          electric: 'Electricity',
          heating: 'Heating',
        },
        detail:
          'Even to simulate a cooling network, it is useful to indicate the air conditioning needs if one wishes to test geothermal energy on probes because this requires cold production in the summer to regenerate the deposit.',
        label: 'Energy needs to cover',
      },
    },
    sourceToDistrictCard: {
      create: 'Select',
      update: 'Save',
      delete: 'Unselect',
      selectSourceLocation: 'Define source location',
      selectSourcePotentials: 'Select source potentials',
      createSourceToDistrictWithMultiplePotentials: 'Validate selection',
      sourceSubtext: {
        biomass:
          'For an optimal construction of scenarios with biomass, select a potential roughly equivalent to the needs of the neighborhood by modulating the number of tiles chosen',
        geothermalGroundwater:
          'Visit https://www.geothermies.fr/viewer/?al=autolayer_ressource_surface_ouvert to find the depth and flow rate of the desired location.',
        geothermalGroundwaterWarning:
          'Beware, enter a depth between 50 m minimum and 3000 m maximum and the flow rate between 50 m3/h and 22,000 m³/h',
        geothermalProbe:
          'Place the source on the map where the potential is the highest and where the heat pump room witll be',
        sewageSanitationNetwork: 'Place the source on the map where the heat pump room witll be',
      },
    },
    sourceToDistrictForm: {
      categories: {
        distribution: 'Distribution',
        exploitationConstraints: 'Exploitation constraints',
        heatExchanger: 'Heat exchanger',
        heatPump: 'Thermodynamic system',
        source: 'Source',
        sourceToDistrict: 'General project parameters',
        storage: 'Storage',
      },
    },
    seeSimulations: 'See simulations',
    sourceDetails: {
      fieldNames: {
        activityCodeIcpe: 'Main activity',
        activityCodeNaf: 'Main activity',
        averageExtractibleVolume: 'Average extractible volume (m³/year)',
        biomethanePotential: 'Selected biomethanation potential (GWh/year)',
        buildingSurface: 'Building surface (m²)',
        buildingVolume: 'Building volume (m³)',
        cantonName: 'Canton name',
        dataSource: 'Data source',
        dataSourceUrl: 'Classified facility sheet',
        departmentName: 'Department',
        flowRate: 'Average incoming flow rate (m³/d)',
        forestSurface: 'Forest surface (m²)',
        forestZoneId: 'Forest zone ID',
        geothermalConductivity: 'Geothermal conductivity (W/m.K)',
        geothermalDepth: 'Depth (m)',
        geothermalFlowRate: 'Flow rate (m³/hour)',
        itSurface: 'IT room surface (m²)',
        location: 'Localisation',
        mainTreeType: 'Main tree type',
        methanationPotential: 'Selected methanation potential (GWh/year)',
        name: 'Name',
        potentialGeothermalEnergy: 'Extractible energy potential (W/m)',
        power_high_temperature_kw: 'High temperature power (kW)',
        power_high_temperature_mw: 'High temperature power (MW)',
        power_low_temperature_kw: 'Low temperature power (kW)',
        power_low_temperature_mw: 'Low temperature power (MW)',
        pyrogasificationPotential: 'Selected pyrogasification potential (GWh/year)',
        siret: 'Siret',
        temperature: 'Temperature (°C)',
        totalSelectedPotential: 'Average energy potential (kWh/year)',
        wasteCapacity: 'Yearly waste capacity (ton/year)',
        year: 'Last update year',
      },
      dataNeeded: {
        default: 'Please provide the missing information before selecting this source.',
        industrial_waste_heat:
          'Before being able to select this source, it is necessary to complete some missing information. The first field corresponds to the sum of the powers of equipment releasing waste heat at over 90°C, and the second corresponds to the sum of the powers of equipment releasing waste heat at less than 90°C.',
      },
      fieldValues: {
        activityCodeIcpe: {
          1511: 'Cold stores, with the exception of warehouses used to store of materials, products or substances',
          2210: 'Animal slaughtering',
          2220: 'Preparation of foodstuffs of plant origin',
          2230: 'Reception, storage, treatment, processing of milk',
          2250: 'Production by distillation of potable alcohol of agricultural origin',
          2251: 'Preparation and packaging of wine',
          2252: 'Preparation and packaging of cider',
          2253: 'Preparation and packaging of beverages',
          2260: 'Grinding, crushing, screening ... of vegetable substances and all natural organic products.',
          2265: 'Acetic fermentation in liquid medium',
          2275: 'Yeast manufacturing',
          2315: 'Manufacturing of artificial plant fibers',
          2321: 'Fabric making workshop',
          2330: 'Dyeing, printing, coating, bleaching and washing of textile materials',
          2340: 'Laundromat, laundry service',
          2350: 'Tanneries, megachurches',
          2410: 'Working with wood and similar combustible materials.',
          2420: 'Manufacturing of charcoal',
          2430: 'Preparation of paper pulp',
          2440: 'Manufacturing of cardboard paper',
          2515: 'Grinding, crushing, screening, bagging, pulverizing, cleaning, sieving, mixing of stones, pebbles, ores and other natural or artificial mineral products or inert non-hazardous waste.',
          2520: 'Manufacturing of cement, lime, plaster',
          2521: 'Asphalt coating plant for road materials',
          2522: 'Plant for manufacturing concrete products by mechanical means.',
          2523: 'Manufacturing of ceramic and refractory products',
          2525: 'Melting of mineral materials',
          2530: 'Manufacturing and working of glass',
          2542: 'Manufacturing of coke',
          2545: 'Manufacturing of steel, iron, cast iron, ferro-alloys',
          2546: 'Industrial processing of non-ferrous ores, processing of non-ferrous metals and alloys',
          2547: 'Manufacturing of silico-alloys or silicon carbide',
          2550: 'Foundry of molded products containing lead',
          2551: 'Foundry of metals and ferrous alloys',
          2552: 'Foundry of non-ferrous metals and alloys',
          2560: 'Mechanical working of metals and alloys',
          2561: 'Hardening and tempering of metals and alloys',
          2562: 'Industrial heating and treatment with molten salt baths',
          2567: 'Galvanization, tinning of metals',
          2575: 'Use of abrasive materials',
          2610: 'Industrial manufacturing by chemical transformation of fertilizers',
          2620: 'Manufacturing of organic sulphide compounds',
          2630: 'Manufacturing of, or based on, detergents and soaps',
          2631: 'Steam extraction of perfumes and essential oils',
          2740: 'Incineration of dead pets',
          2750: 'Collective treatment plant for industrial wastewater',
          2752: 'Mixed wastewater treatment plant',
          2770: 'Thermal treatment of hazardous waste',
          2771: 'Thermal treatment of non-hazardous waste',
          2910: 'Combustion plant',
          2915: 'Heating processes',
          2920: 'Compression system',
          2921: 'Evaporative cooling by water dispersion in an air stream',
          2925: 'Electric battery charging workshops',
          2971: 'waste incineration plant',
          3110: 'Combustion of fuels in plants with a total rated thermal input of 50 MW or more.',
          3230: 'Ferrous metal processing',
          9999: 'Various',
        },
        activityCodeNaf: {
          '0111Z': 'Cultivation of cereals (except rice), pulses and oil seeds',
          '0113Z': 'Growing of vegetables, melons, roots and tubers',
          '0119Z': 'Other non-permanent crops',
          '0121Z': 'Cultivation of vines',
          '0124Z': 'Breeding of other cattle and buffaloes',
          '0125Z': 'Cultivation of other tree or shrub fruits and nuts',
          '0129Z': 'Other permanent crops',
          '0130Z': 'Plant reproduction',
          '0141Z': 'Dairy cattle breeding',
          '0142Z': 'Raising other cattle and buffalo',
          '0143Z': 'Breeding of horses and other equines',
          '0145Z': 'Sheep and goat breeding',
          '0146Z': 'Hog Farming',
          '0147Z': 'Poultry breeding',
          '0149Z': 'Breeding of other animals',
          '0150Z': 'Associated culture and breeding',
          '0161Z': 'Crop support activities',
          '0162Z': 'Livestock production support activities',
          '0220Z': 'Logging',
          '0240Z': 'Logging support services',
          '0322Z': 'Freshwater aquaculture',
          '0610Z': 'Extraction of crude oil',
          '0729Z': 'Mining of other non-ferrous metal ores',
          '0811Z':
            'Mining of ornamental and building stone, industrial limestone, gypsum, chalk and slate',
          '0812Z': 'Mining of gravel and sand pits, extraction of clay and kaolin',
          '0891Z': 'Mining of chemical minerals and mineral fertilizers',
          '0892Z': 'Peat extraction',
          '0899Z': 'Other mining and quarrying n.e.c.',
          '0910Z': 'Support activities for oil and gas extraction',
          '0990Z': 'Support activities for other extractive industries',
          '1011Z': 'Meat Processing and Preservation',
          '1012Z': 'Processing and preservation of poultry meat',
          '1013A': 'Industrial preparation of meat products',
          '1013B': 'Charcuterie',
          '1020Z': 'Processing and preservation of fish, crustaceans and molluscs',
          '1031Z': 'Potato processing and preserving',
          '1032Z': 'Fruit and vegetable juice preparation',
          '1039A': 'Other vegetable processing and preservation',
          '1039B': 'Fruit processing and preserving',
          '1041A': 'Manufacture of refined oils and fats',
          '1041B': 'Manufacture of refined oils and fats',
          '1042Z': 'Manufacture of margarine and similar edible fats',
          '1051A': 'Manufacture of liquid milk and fresh products',
          '1051B': 'Butter production',
          '1051C': 'Cheese manufacturing',
          '1051D': 'Manufacture of other dairy products',
          '1052Z': 'Ice cream and sherbet manufacturing',
          '1061A': 'Milling',
          '1061B': 'Other grain processing activities',
          '1062Z': 'Starch Product Manufacturing',
          '1071A': 'Industrial manufacture of bread and fresh pastry',
          '1071B': 'Baking of bakery products',
          '1071C': 'bakery pastry',
          '1071D': 'Pastry making',
          '1072Z': 'Manufacture of cookies, rusks and preserved pastries',
          '1073Z': 'Pasta Manufacturing',
          '1081Z': 'Sugar Manufacturing',
          '1082Z': 'Manufacture of cocoa, chocolate and confectionery products',
          '1083Z': 'Tea and coffee processing',
          '1084Z': 'Manufacture of condiments and seasonings',
          '1085Z': 'Manufacture of prepared meals',
          '1086Z': 'Manufacture of homogenized and dietetic foods',
          '1089Z': 'Other Technical and Industrial Textile Manufacturing',
          '1091Z': 'Manufacture of farm animal feed',
          '1092Z': 'Manufacture of pet food',
          '1101Z': 'Manufacture of distilled alcoholic beverages',
          '1102A': 'Manufacture of sparkling wines',
          '1102B': 'Wine-making',
          '1103Z': 'Manufacture of cider and fruit wines',
          '1104Z': 'Production of other non-distilled fermented beverages',
          '1105Z': 'Beer manufacturing',
          '1106Z': 'Manufacture of malt',
          '1107A': 'Table water industry',
          '1107B': 'Soft drink production',
          '1200Z': 'Manufacture of tobacco products',
          '1310Z': 'Textile fiber preparation and spinning',
          '1320Z': 'Weaving',
          '1330Z': 'Textile finishing',
          '1391Z': 'Manufacture of knitted and crocheted fabrics',
          '1392Z': 'manufacture of textile articles of furniture',
          '1393Z': 'Manufacture of carpets and rugs',
          '1395Z': 'Manufacture of non-wovens',
          '1396Z': 'Manufacture of other technical and industrial textiles',
          '1399Z': 'Manufacture of other textiles n.e.c.',
          '1412Z': 'Manufacture of work clothing',
          '1414Z': 'Manufacture of underwear',
          '1420Z': 'Manufacture of fur products',
          '1431Z': 'Manufacture of knitted footwear',
          '1511Z': 'Leather dressing and tanning; preparation and dyeing of furs',
          '1512Z': 'Manufacture of travel goods, leather goods and saddlery',
          '1520Z': 'Manufacture of footwear',
          '1610A': 'Sawing and planing of wood, excluding impregnation',
          '1610B': 'Impregnation of wood',
          '1621Z': 'Veneer and Wood Panel Manufacturing',
          '1622Z': 'Manufacture of assembled wooden floors',
          '1623Z': 'Manufacture of carpentry and other joinery',
          '1624Z': 'Wood Packaging Manufacturing',
          '1629Z':
            'Manufacture of various wooden objects; manufacture of cork, basketry and straw goods',
          '1711Z': 'Pulp and paper manufacturing',
          '1712Z': 'Manufacture of paper and cardboard',
          '1721A': 'Corrugated Cardboard Manufacturing',
          '1721B': 'Manufacture of cardboard',
          '1721C': 'Manufacture of paper packaging',
          '1722Z': 'Manufacture of paper products for sanitary and household purposes',
          '1723Z': 'Manufacture of paper products',
          '1724Z': 'Wallpaper manufacturing',
          '1729Z': 'Manufacture of other articles of paper and paperboard',
          '1811Z': 'Newspaper Printing',
          '1812Z': 'Other Printing (Commercial)',
          '1813Z': 'Printing, Screen Printing, Advertising and Syntec',
          '1814Z': 'Bookbinding and related activities',
          '1820Z': 'Playback of recordings',
          '1920Z': 'Oil refining',
          '2011Z': 'Industrial Gas Manufacturing',
          '2012Z': 'Manufacture of dyes and pigments',
          '2013B': 'Other Basic Inorganic Chemical Manufacturing n.e.c.',
          '2014Z': 'Other Basic Organic Chemical Manufacturing',
          '2015Z': 'Manufacture of nitrogen products and fertilizers',
          '2016Z': 'Basic Plastic Manufacturing',
          '2017Z': 'Manufacture of synthetic rubber',
          '2020Z': 'Manufacture of other technical and industrial textiles',
          '2030Z': 'Manufacture of paints, varnishes, inks and sealants',
          '2041Z': 'Manufacture of soap, detergents and cleaning products',
          '2042Z': 'Perfume and Toilet Preparation Manufacturing',
          '2051Z': 'Manufacture of explosives',
          '2052Z': 'Manufacture of adhesives',
          '2053Z': 'Essential Oil Manufacturing',
          '2059Z': 'Other chemical product manufacturing n.e.c.',
          '2060Z': 'Manufacture of artificial and synthetic fibres',
          '2110Z': 'Manufacture of basic pharmaceutical products',
          '2120Z': 'Manufacture of pharmaceutical preparations',
          '2211Z': 'Manufacture and retreading of tires',
          '2219Z': 'Other Rubber Product Manufacturing',
          '2221Z': 'Manufacture of plastic plates, sheets, tubes and profiles',
          '2222Z': 'Manufacture of plastic packaging',
          '2223Z': 'Manufacture of plastic components for construction',
          '2229A': 'Manufacture of technical parts based on plastic materials',
          '2229B': 'Other technical and industrial textile manufacturing',
          '2311Z': 'Manufacture of flat glass',
          '2312Z': 'Shaping and processing of flat glass',
          '2313Z': 'Other Technical and Industrial Textile Manufacturing',
          '2314Z': 'Fiberglass manufacturing',
          '2319Z': 'Manufacture and processing of other glassware, including technical glass',
          '2320Z': 'Manufacture of refractory products',
          '2331Z': 'Manufacture of ceramic tiles',
          '2332Z': 'Manufacture of clay bricks, tiles and construction products',
          '2341Z': 'Manufacture of ceramic articles for domestic or ornamental use',
          '2342Z': 'Manufacture of ceramic sanitary ware',
          '2343Z': 'Manufacturing of ceramic insulators and insulating parts',
          '2344Z': 'Other Technical Ceramic Product Manufacturing',
          '2351Z': 'Manufacture of cement',
          '2352Z': 'Manufacture of lime and plaster',
          '2361Z': 'Manufacture of concrete elements for construction',
          '2362Z': 'Manufacture of plaster elements for construction',
          '2363Z': 'Manufacture of ready-mixed concrete',
          '2364Z': 'Manufacture of dry mortar and concrete',
          '2365Z': 'Manufacture of fibre cement products',
          '2369Z': 'Manufacture of other articles of concrete, cement and plaster',
          '2370Z': 'Stone cutting, shaping and finishing',
          '2391Z': 'Manufacturing of abrasive products',
          '2399Z': 'Other Non-Metallic Mineral Product Manufacturing n.e.c.',
          '2410Z': 'Iron and steel industry',
          '2420Z': 'Manufacture of steel tubes, pipes, hollow sections and related fittings',
          '2431Z': 'Cold drawing of bars',
          '2432Z': 'Cold rolling of strip steel',
          '2433Z': 'Cold forming and bending',
          '2434Z': 'Cold drawing',
          '2442Z': 'Aluminium metallurgy',
          '2443Z': 'Lead, zinc or tin metallurgy',
          '2444Z': 'Copper metallurgy',
          '2445Z': 'Manufacture of other non-ferrous metals',
          '2446Z': 'Development and transformation of nuclear materials',
          '2451Z': 'Cast iron foundry',
          '2452Z': 'Steel casting',
          '2453Z': 'Foundry of light metals',
          '2454Z': 'Other non-ferrous metal foundry',
          '2511Z': 'Manufacture of metal structures and parts of structures',
          '2512Z': 'Manufacture of metal doors and windows',
          '2521Z': 'Manufacture of radiators and boilers for central heating',
          '2529Z': 'Manufacture of other tanks, reservoirs and containers of metal',
          '2530Z': 'Manufacture of steam generators, except central heating boilers',
          '2540Z': 'Manufacture of arms and ammunition',
          '2550A': 'Forging, stamping, pressing; powder metallurgy',
          '2550B': 'Cutting, stamping',
          '2561Z': 'Metal treatment and coating',
          '2562A': 'Screw-cutting',
          '2562B': 'Industrial mechanics',
          '2571Z': 'Manufacture of cutlery',
          '2572Z': 'Locks and Fittings Manufacturing',
          '2573A': 'Mould and model making',
          '2573B': 'Manufacture of other tools',
          '2591Z': 'Manufacture of drums and similar metal containers',
          '2592Z': 'Light metal packaging manufacturing',
          '2593Z': 'Manufacture of wire products, chains and springs',
          '2594Z': 'Manufacture of screws and bolts',
          '2599A': 'Manufacture of household metal goods',
          '2599B': 'Other Fabricated Metal Product Manufacturing',
          '2611Z': 'Electronic Component Manufacturing',
          '2612Z': 'Manufacture of assembled electronic cards',
          '2620Z': 'Manufacture of computers and peripheral equipment',
          '2651A': 'Manufacture of navigational aids',
          '2651B': 'Manufacture of scientific and technical instruments',
          '2652Z': 'Movement for clocks of all types',
          '2660Z':
            'Manufacture of medical irradiation equipment, electromedical and electrotherapeutic equipment',
          '2670Z': 'Manufacture of optical and photographic material',
          '2680Z': 'Manufacture of magnetic and optical storage media',
          '2711Z': 'Manufacture of electric motors, generators and transformers',
          '2712Z': 'Electrical Distribution and Control Equipment Manufacturing',
          '2720Z': 'Manufacture of batteries and electric accumulators',
          '2731Z': 'Manufacture of optical fibre cables',
          '2732Z': 'Manufacture of other electronic and electric wires and cables',
          '2733Z': 'Manufacture of electrical installation equipment',
          '2740Z': 'Manufacture of electric lighting equipment',
          '2751Z': 'Manufacture of household appliances',
          '2752Z': 'Non-electrical household appliance manufacturing',
          '2790Z': 'Manufacture of other electrical equipment',
          '2811Z': 'Manufacture of engines and turbines, except aircraft and vehicle engines',
          '2812Z': 'Manufacture of hydraulic and pneumatic equipment',
          '2813Z': 'Manufacture of other pumps and compressors',
          '2814Z': 'Other Fittings Manufacturing',
          '2815Z': 'Transmission shafts and cranks',
          '2822Z': 'Manufacture of lifting and handling equipment',
          '2823Z': 'Manufacture of office machinery and equipment (excluding computers)',
          '2825Z': 'Manufacture of industrial air conditioning and refrigeration equipment',
          '2829A': 'Manufacture of packaging, weighing and filling equipment',
          '2829B': 'Other General-Purpose Machinery Manufacturing',
          '2830Z': 'Manufacture of agricultural and forestry machinery',
          '2841Z': 'Manufacture of machine tools for metal working',
          '2849Z': 'Manufacture of other machine tools',
          '2891Z': 'Metalworking Machinery Manufacturing',
          '2892Z': 'Manufacture of machinery for mining and construction',
          '2893Z': 'Manufacture of machinery for the food industry',
          '2894Z': 'Manufacture of machinery for textile industries',
          '2895Z': 'Manufacture of machinery for the paper and cardboard industries',
          '2896Z': 'Manufacture of machines for the working of rubber or plastic materials',
          '2899A': 'Other manufacturing not elsewhere classified',
          '2899B': 'Manufacture of other specialized machinery',
          '2910Z': 'Manufacture of motor vehicles',
          '2920Z': 'Manufacture of bodies and trailers',
          '2931Z': 'Manufacture of electrical and electronic equipment for motor vehicles',
          '2932Z': 'Manufacture of other automotive equipment',
          '3011Z': 'Manufacture of ships and floating structures',
          '3012Z': 'Construction of pleasure boats',
          '3020Z': 'Other Technical and Industrial Textile Manufacturing',
          '3030Z': 'Aeronautical and space construction',
          '3040Z': 'Construction of military combat vehicles',
          '3091Z': 'Manufacture of motorcycles',
          '3092Z': 'Manufacture of bicycles and vehicles for the disabled',
          '3101Z': 'Manufacture of office and store furniture',
          '3102Z': 'Manufacture of kitchen furniture',
          '3103Z': 'Manufacture of mattresses',
          '3109A': 'Manufacture of indoor furniture seats',
          '3109B': 'Other Furniture and Related Furnishings Manufacturing',
          '3211Z': 'Coin minting',
          '3212Z': 'Manufacture of jewelry and related articles',
          '3213Z': 'Manufacture of imitation jewelry and similar articles',
          '3220Z': 'manufacturing of musical instrument parts and accessories',
          '3230Z': 'Sporting Goods Manufacturing',
          '3240Z': 'Manufacture of games and toys',
          '3250A': 'Manufacture of medical, surgical and dental equipment',
          '3250B': 'Manufacture of spectacles',
          '3291Z': 'Manufacture of brush articles',
          '3299Z': 'Other manufacturing activities n.e.c.',
          '3311Z': 'Repair of fabricated metal products',
          '3312Z': 'Repair of machinery and mechanical equipment',
          '3314Z': 'Repair of electrical equipment',
          '3315Z': 'Naval repair and maintenance',
          '3316Z': 'Repair and maintenance of aircraft and spacecraft',
          '3317Z': 'Repair and Maintenance of Other Transport Equipment',
          '3319Z': 'Repair of Other Equipment',
          '3320A': 'Installation of Metal Structures, Boilermaking Works and Piping',
          '3320B': 'Installation of machinery and mechanical equipment',
          '3320C': 'Design and On-site Assembly of Industrial Process Control Equipment',
          '3320D': 'Installation of electrical, electronic, optical and other equipment',
          '3511Z': 'Production of electricity',
          '3512Z': 'Transport of electricity',
          '3513Z': 'Electricity distribution',
          '3514Z': 'Electricity trade',
          '3521Z': 'Production of gaseous fuels',
          '3523Z': 'Trade in gaseous fuels through pipelines',
          '3530Z': 'Production and distribution of steam and air conditioning',
          '3600Z': 'Collection, treatment and distribution of water',
          '3700Z': 'Collection and treatment of waste water',
          '3811Z': 'Collection of non-hazardous waste',
          '3812Z': 'Collection of hazardous waste',
          '3821Z': 'Non-hazardous Waste Treatment and Disposal',
          '3822Z': 'Treatment and disposal of hazardous waste',
          '3831Z': 'Dismantling of wrecks',
          '3832Z': 'Recovery of sorted waste',
          '3900Z': 'Pollution control and other waste management services',
          '4110A': 'Residential Property Development',
          '4110B': 'Office Property Development',
          '4110C': 'Development of Other Buildings',
          '4110D': 'Legal Structures for Programs',
          '4120A': 'Building of Individual Houses',
          '4120B': 'construction of non-residential buildings of all types',
          '4211Z': 'Road and highway construction',
          '4213A': 'Construction of engineering structures',
          '4221Z': 'Construction of fluid networks',
          '4222Z': 'Construction of electrical and telecommunications networks',
          '4291Z': 'Construction of Maritime and Fluvial Structures',
          '4299Z': 'Construction of Other Civil Engineering Projects, Not Elsewhere Classified',
          '4311Z': 'Demolition Works',
          '4312A': 'Standard earthworks and preparatory work',
          '4312B': 'Specialised or Large-scale Earthmoving Works',
          '4321A': 'Electrical Installation Works in All Types of Buildings',
          '4322A': 'Water and Gas Installation Works in All Types of Buildings',
          '4322B': 'Heating, Ventilation, and Air Conditioning (HVAC) Installation Works',
          '4329A': 'Insulation work',
          '4332A': 'Wood and PVC Joinery Works',
          '4332B': 'Metal Joinery and Locksmith Works',
          '4332C': 'Fitting out of sales outlets',
          '4334Z': 'Painting and glassware',
          '4391A': 'Carpentry work',
          '4391B': 'Rooftile Works',
          '4399A': 'Waterproofing work',
          '4399C': 'General masonry and building construction work',
          '4399D':
            'Specialised Construction Activities Which Concern a Common Aspect of Different Projects And Require Specialised Skills Or Equipment',
          '4399E': 'Rental with Operator of Construction Machinery',
          '4511Z': 'Retail Sale of Motor Vehicles, except Motorcycles and Cycles',
          '4519Z': 'Retail Sale of Other Motor Vehicles',
          '4520A': 'Maintenance and Repair of Light Motor Vehicles',
          '4520B': 'Maintenance and Repair of Other Motor Vehicles',
          '4531Z': 'Wholesale Trade of Automotive Fuel and Lubricants',
          '4532Z': 'Retail Sale of Automotive Fuel, Lubricants and Related Products',
          '4540Z': 'Retail Sale of Motorcycles',
          '4612B': 'Wholesale trade, except of motor vehicles and motorcycles',
          '4613Z': 'Intermediaries in the wood and building materials trade',
          '4616Z':
            'Agents involved in the sale of textiles, clothing, furs, footwear and leather goods',
          '4617A': 'Food purchasing centres',
          '4618Z': 'Intermediaries specializing in the sale of other specific products',
          '4619A': 'Non-food buying groups',
          '4619B': 'Commission agents dealing in securities and other financial assets',
          '4621Z': 'Wholesale of grain, unmanufactured tobacco, seeds and animal feed',
          '4622Z': 'Wholesale trade of flowers, plants, seeds etc.',
          '4623Z': 'Wholesale trade (business to business) of live animals',
          '4624Z': 'Wholesale trade (inter-company trade) of hides and skins',
          '4631Z': 'Wholesale (business-to-business) of fruit and vegetables',
          '4632A': 'Wholesale (business to business) of meat products',
          '4632B': 'Wholesale trade (business-to-business) of meat products',
          '4632C': 'Wholesale trade of meat products',
          '4633Z': 'Wholesale of dairy products, eggs, edible oils and fats',
          '4634Z': 'Wholesale (business to business) of beverages',
          '4635Z': 'Wholesale trade (business-to-business) of tobacco products',
          '4638A': 'Wholesale (business-to-business) of fish, crustaceans and molluscs',
          '4638B': 'Wholesale trade (business-to-business) of other specialized foodstuffs',
          '4639A':
            'Wholesale trade (inter-company trade) of frozen products) of the company according to the INSEE classification',
          '4639B': 'Non-specialized food wholesale',
          '4641Z': 'Wholesale trade (business to business) of textiles',
          '4642z': 'Wholesale trade (business-to-business) of clothing and footwear',
          '4643Z': 'Wholesale (business-to-business) of household appliances',
          '4644Z':
            'Wholesale (business-to-business) of tableware, glassware and cleaning products',
          '4645Z': 'Wholesale (business-to-business) of perfume and cosmetics',
          '4646Z': 'Wholesale (business-to-business) of pharmaceutical products',
          '4647Z': 'Wholesale trade of furniture, lighting equipment etc.',
          '4649Z': 'Manufacture of steel tubes, pipes, hollow sections and related accessories',
          '4651Z':
            'Wholesale (business-to-business) of computers, computer peripheral equipment and software',
          '4652Z':
            'Wholesale trade (business-to-business) of electronic and telecommunication components and equipment',
          '4661Z': 'Wholesale trade (business to business) of agricultural equipment',
          '4662Z': 'Wholesale trade (business to business) of machine tools',
          '4663Z':
            "Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil",
          '4669A': 'Wholesale trade (business-to-business) of electrical equipment',
          '4669B': 'Wholesale (business-to-business) of other industrial supplies and equipment',
          '4669C':
            'Wholesale (business-to-business) of various supplies and equipment for trade and services',
          '4671Z': 'Wholesale of fuels and related products',
          '4672Z': 'Wholesale (business-to-business) of ores and metals',
          '4673A': 'Wholesale trade (business-to-business) of wood and building materials',
          '4673B':
            'Wholesale (business-to-business) of sanitary appliances and decorative products',
          '4674A': 'Wholesale trade (business to business) of hardware',
          '4674B': 'Wholesale trade (business-to-business) of plumbing and heating supplies',
          '4675Z': 'Wholesale of chemical products',
          '4676Z': 'Wholesale trade (business-to-business) of other intermediate products',
          '4677Z': 'Wholesale (business-to-business) of waste and scrap',
          '4690Z': 'Non-specialized wholesale trade (business-to-business)',
          '4711B': 'General food trade',
          '4711D': 'Supermarkets',
          '4711F': 'Hypermarkets',
          '4719A': 'Retail sale in specialised stores with a floor area of over 250m²',
          '4719B':
            'Other retail sale in specialised stores with a floor area of less than 250m²',
          '4721Z': 'Retail sale of fruit and vegetables in specialised stores',
          '4723Z': 'Retail Sale of Fish, Shellfish and Crustaceans in Specialized Stores',
          '4724Z': 'Retail Sale of Bread, Pastries and Confectionery in Specialized Stores',
          '4725Z': 'Retail sale of beverages in specialized stores',
          '4729Z': 'Other Food Retail Sales in Specialized Stores',
          '4730Z': 'Retail sale of fuel in specialized stores',
          '4751Z': 'Retail sale of textiles in specialized stores',
          '4752A':
            'Retail Sale of Hardware, Paints and Glass in Small Surface Areas (less than 400 m²)',
          '4752B': 'Retail sale of hardware, paints and glass in large stores (400 m² and more)',
          '4754Z': 'Retail sale of household appliances in specialized stores',
          '4759A': 'Retail Sale of Furniture',
          '4759B': 'Retail trade of other household equipment',
          '4761Z': 'Retail Sale of Books in Specialized Stores',
          '4762Z': 'Retail sale of newspapers and stationery in specialized stores',
          '4764Z': 'Retail Sale of Sports Articles in Specialized Stores',
          '4765Z': 'Retail sale of games and toys in specialized stores',
          '4771Z': 'Retail Sale of Clothing in Specialized Stores',
          '4774Z': 'Retail Sale of Medical and Orthopaedic Goods in Specialized Stores',
          '4776Z':
            'Retail Sale of Flowers, Plants, Seeds, Fertilizers, Pets and Animal Feed in Specialized Stores',
          '4778A': 'Optical Goods Retail Trade',
          '4778B': 'Retail sale of coal and fuels',
          '4778C': 'Other miscellaneous specialized retail trade',
          '4781Z': 'Food retailing on stalls and markets',
          '4789Z': 'Other Retail Sale on Markets',
          '4791A': 'Distance selling from general catalogs',
          '4791B': 'Mail Order Retail Sales Specialized in Certain Products',
          '4799A': 'Direct Selling',
          '4910Z': 'Intercity passenger rail transportation',
          '4920Z': 'Rail freight transport',
          '4931Z': 'Urban and Suburban Passenger Transportation',
          '4939C': 'Rope-and-Bucket Elevators and Inclined Conveyors',
          '4941A': 'Intercity freight road transport',
          '4941B': 'Local road freight transport',
          '4941C': 'Truck rental with driver',
          '4950Z': 'Transportation via pipelines',
          '5110Z': 'Passenger air transport',
          '5122Z': 'Spacecraft manufacturing',
          '5210A': 'Cold storage and warehousing',
          '5210B': 'Warehousing and non-refrigerated storage',
          '5221Z': 'Services incidental to land transportation',
          '5222Z': 'Auxiliary services for water transport',
          '5223Z': 'Services incidental to air transport',
          '5224A': 'Port Handling',
          '5224B': 'Non-port handling',
          '5229A': 'Courier, express freight',
          '5229B': 'Chartering and organization of transport',
          '5310Z': 'Postal Activities Under Universal Service Obligation',
          '5320Z': 'Other Postal and Courier Activities',
          '5510Z': 'Hotels and Similar Accommodation',
          '5520Z': 'Tourist and Other Short-Stay Accommodation',
          '5629A': 'Contract catering',
          '5629B': 'Other food services n.e.c.',
          '5811Z': 'Book Publishing',
          '5813Z': 'Newspaper publishing',
          '6110Z': 'Wired telecommunications',
          '6120Z': 'Wireless Telecommunications Activities',
          '6190Z': 'Other telecommunication activities',
          '6202A': 'Consulting in computer systems and software',
          '6203Z': 'Computer Facilities Management',
          '6311Z': 'Data processing, accommodation and related activities',
          '6399Z': 'Other information services n.e.c.',
          '6411Z': 'Activities of Central Banks',
          '6419Z': 'Other monetary intermediation',
          '6420Z': 'Other Technical and Industrial Textile Manufacturing',
          '6430Z': 'Investment funds and similar financial entities',
          '6619A': 'Administration of Mobilien Property Portfolio',
          '6619B':
            'Other activities auxiliary to financial services, except insurance and pension funding, n.e.c.',
          '6630Z': 'Fund Management Activities',
          '6810Z': 'Real Estate Merchant Activities',
          '6820A': 'Rental of dwellings',
          '6820B': 'Rental of land and other real estate',
          '6831Z': 'Real Estate Agencies',
          '6832A': 'Other Technical and Industrial Textile Manufacturing',
          '7010Z': 'Head office activity',
          '7022Z': 'Business and other management consultancy',
          '7112B': 'Engineering, technical studies',
          '7120B': 'Technical analysis, testing and inspection',
          '7211Z': 'Biotechnology research and development',
          '7219Z': 'Research and development in other physical and natural sciences',
          '7220Z': 'Research and development in humanities and social sciences',
          '7311Z': 'Advertising agency activities',
          '7312Z': 'Media Advertising',
          '7420Z': "Photographers' Code",
          '7490A': 'Activities of Construction Economists',
          '7490B': 'Various specialized, scientific and technical activities',
          '7729Z': 'Rental and leasing of other personal and household goods',
          '7731Z': 'Rental and leasing of agricultural machinery and equipment',
          '7732Z':
            'Renting and Operating of Construction and Civil Engineering Machinery Without Operator',
          '7739Z': 'Renting and Operating of Other Machinery, Equipment and Impersonal Goods',
          '7740Z':
            'Leasing of intellectual property and similar products, except for copyrighted works',
          '7830Z': 'Other provision of human resources',
          '7990Z': 'Other Reservation Service Activities',
          '8110Z': 'Combined Facility Support Services',
          '8121Z': 'General Building Cleaning',
          '8122Z': 'Other building and industrial cleaning activities',
          '8129B': 'Other cleaning activities n.e.c.',
          '8130Z': 'Landscape Services',
          '8211Z': 'Combined office and administrative services',
          '8219Z':
            'Photocopying, Document Preparation and Other Specialized Office Support Activities',
          '8230Z': 'Organization of fairs, trade shows and conventions',
          '8292Z': 'Packaging activities',
          '8299Z': 'Other business support activities n.e.c.',
          '8411Z': 'General public administration',
          '8412Z':
            'Public administration (guardianship) of health, education, culture and social services, other than social security',
          '8413Z': 'Public administration (supervision) of economic activities',
          '8425Z': 'Fire and rescue services',
          '8510Z': 'Pre-Primary Education',
          '8520Z': 'Primary Education',
          '8531Z': 'General secondary education',
          '8532Z': 'Technical or vocational secondary education',
          '8542Z': 'Higher education',
          '8551Z': 'Sports and Recreation Activities',
          '8559A': 'Independent Training Providers',
          '8559B': 'Collective Agreements',
          '8610Z': 'Hospital Activities',
          '8621Z': 'General Medical Practices',
          '8622C': 'Other Human Health Activities',
          '8690C': 'Organ and Tissue Banks',
          '8790B':
            'Social housing for adults and families in difficulty and other social housing',
          '8810B': 'Home or support without accommodation for disabled or elderly adults',
          '8810C': 'Helping people to work',
          '8891A': 'Crèche Activities',
          '8899B': 'Social work without accommodation n.e.c.',
          '9001Z': 'Performing Arts',
          '9003A': 'Visual Arts',
          '9003B': 'Other Creative Arts',
          '9004Z': 'Operation of Arts Facilities',
          '9101Z': 'Library Activities',
          '9103Z':
            'Management of historical sites and monuments and similar tourist attractions',
          '9104Z': 'Management of botanical and zoological gardens and nature reserves',
          '9200Z': 'Gambling and Betting Activities',
          '9311Z': 'Sports Facility Operation',
          '9312Z': 'Sports Clubs Activities',
          '9319Z': 'Other Sporting Activities',
          '9321Z': 'Amusement and Recreation Activities',
          '9329Z': 'Gaming Machine Manufacturing',
          '9411Z': 'Business and Other Personal Service Activities',
          '9412Z': 'Activities of professional organizations',
          '9420Z': 'Activities of employee unions',
          '9499Z': 'Other organizations operating on a voluntary basis',
          '9511Z': 'Repair of Computer Peripherals',
          '9512Z': 'Repair of communication equipment',
          '9529Z': 'Repair of Personal Goods, Except Wearing Apparel',
          '9601A': 'Laundry and dry cleaning, wholesale',
          '9601B': 'Laundry',
          '9602A': 'Hairdressing Services',
          '9602B': 'Beauty care',
          '9603Z': ' Funeral services',
          '9604Z': 'Beauty Treatment Activities',
          '9609Z': 'Other personal services n.e.c.',
          '9900Z': 'Activities of extraterritorial Organizations and bodies',
          9999: 'Miscellaneous',
        },
      },
    },
    networkDetails: {
      CO2Emisions: 'CO2 emissions (g/kWh)',
      cogeneration: 'Cogeneration',
      cogenerationElectricity: 'Cogeneration (electricity produced in MWh/year)',
      coldDelivery: 'Cold delivery',
      coldProductionPercentBySource: {
        absorption: 'Absorption cooling: {percent}%',
        compression: 'Compression cooling: {percent}%',
        heatpump: 'Heat pump: {percent}%',
        other: 'Other: {percent}%',
        passive: 'Passive cooling: {percent}%',
      },
      coldProductionPercentTitle: 'Energy mix (% of the power): ',
      communeName: 'Commune name',
      heatProductionPercentBySource: {
        biogas: 'Biogas: {percent}%',
        biomass: 'Biomass: {percent}%',
        coal: 'Coal: {percent}%',
        domesticFuel: 'Domestic fuel: {percent}%',
        electricalBoiler: 'Electric boiler: {percent}%',
        gas: 'Natural gas: {percent}%',
        geothermal: 'Geothermal: {percent}%',
        heatpump: 'Heat pump: {percent}%',
        heavyFuel: 'Heavy fuel: {percent}%',
        industrialWasteHeat: 'Industrial waste heat: {percent}%',
        internalWaste: 'Internal waste: {percent}%',
        lpg: 'LGP: {percent}%',
        other: 'Other: {percent}%',
        otherReneweable: 'Other renewable: {percent}%',
        otherWasteHeat: 'Other waste heat: {percent}%',
        solarThermal: 'Solar thermal: {percent}%',
        wasteIncineration: 'Waste incineration plant: {percent}%',
      },
      heatProductionPercentTitle: 'Energy mix (% of the annual production): ',
      heatTransferFluidPercentByType: {
        hotWater: 'Hot water: {percent}%',
        overHeatedWater: 'Overheated water: {percent}%',
        Steam: 'Steam: {percent}%',
      },
      heatTransferFluidPercentTitle: 'Heat transfer fluid (% of the power): ',
      hotDelivery: 'Hot delivery',
      location: 'Location',
      locationSource: 'Source location',
      mainDataSource: 'Main data source',
      managingCompany: 'Managing company',
      name: 'Name',
      networkLength: 'Network length (km)',
      networkSNCUIdentifier: 'SNCU network identifier',
      networkType: 'Network type',
      rateEnRAndR: 'EnR&R rate (%)',
    },
    highConsumptionBuilding: {
      details: {
        groundSurface: 'Ground surface (m²)',
        height: 'Height (m)',
        purpose: 'Main usage',
        surface: 'Total surface (m²)',
        year: 'Year',
      },
      actions: {
        import: 'Import building',
      },
    },
  },
  resultPage: {
    titleColdSimulation: 'Simulation results for cold production',
    titleHotSimulation: 'Simulation results for hot production',
    titleElectricSimulation: 'Simulation results for electric production',
    backToSimulations: 'Back to simulations',
    backToMap: 'Back to map',
    errorMessage: 'La simulation a échoué avec le message suivant: ',
    colorPopin: {
      title: 'Change legend display',
      selectLabel: 'Filter by indicator',
      sliderLabel: 'Number of shades to display: ',
    },
    lcoeByRenRateGraph: {
      sectionTitle: 'Selection of the best scenario for each slice of EnR&R rate',
      graph: {
        title: 'Best LCOE by slice of target EnR&R rate',
        abscissa: 'LCOE',
        ordinate: 'EnR&R rate',
        labelPrefix: 'Coverage rate',
        mixNumber: 'Mix No. {iterationName}',
      },
    },
    ScenarioComparizonTab: {
      title: 'Scenario comparizon',
    },
    ScenarioDetailsTab: {
      title: 'Scenario Details',
    },
    ScenarioEcoEnvKpiTable: {
      sectionTitle: 'Economic KPIs by source',
      headers: {
        sourceName: 'Source',
        distanceToDistrict: 'Distance to district (m)',
        lcoe: 'LCOE (€/MWh)',
        investmentCost: 'Investment cost (€)',
        operatingCost: 'Operating cost (€/year)',
        energyConsumptionCost: 'Energy cost (€/year)',
      },
    },
    ScenarioEnergyKpiTable: {
      sectionTitle: 'Energetic KPIs by source',
      headers: {
        iterationName: 'Iteration',
        sourceType: 'Source',
        coverageRate: 'Coverage rate (%)',
        renMixProportion: 'Proportion of the EnR&R mix (%)',
        power: 'Installed power (kW)',
        producedEnergy: 'Produced energy (MWh/year)',
        consumedElectricity: 'Consumed electricity (MWh/year)',
        storageVolume: 'Storage volume (m³)',
        loadRate: 'Load rate (%)',
        runTime: 'Time exploited per year (h)',
        fullLoadRunTimeEquivalent: 'Time exploited by year full load equivalent (h)',
        mix: 'Mix No.',
      },
    },
    ScenarioEnergyTimeSeriesGraph: {
      sectionTitle: 'Power load graph',
      graph: {
        title: 'REn&R (Renewable Energy and Recovery) production and remaining load',
        abscissa: 'Power',
        ordinate: 'Time',
        remainingLoadLabel: 'Remaining heating load',
      },
      export: {
        sheetName: 'Production and remaining load',
      },
    },
    ScenarioLoadRatesGraph: {
      sectionTitle: 'Load rates graph',
      graph: {
        title: 'Load rates',
        abscissa: 'Load rate',
        ordinate: 'Time',
      },
    },
    SimulationParametersTable: {
      sectionTitle: 'Reminder of simulation parameters',
      sheetName: 'Reminder of hypotheses',
    },
    ScenarioSummaryTable: {
      sectionTitle: 'Scenario summary table',
      exportSheetName: 'Scenario summary|Scenarios summary',
      energyMix: {
        sectionTitle: 'Energy mix scenarios summary table',
      },
      headers: {
        autoConsumptionRate: 'Autoconsumption rate (%)',
        autoConsumptionZoneName: 'Autoconsumption zone',
        autoProductionRate: 'Autoproduction rate (%)',
        batteryCapacity: 'Capacité de la batterie (kWh)',
        co2: 'CO2 of produced energy (g/kWh)',
        constraintsReachedBySource: 'Exploitation constraints reached',
        coverageRate: 'Coverage rate (%)',
        investmentCost: 'Investment cost (€)',
        iteration: 'Iteration',
        lcoe: 'Global LCOE (€/MWh)',
        mix: 'Mix No.',
        operatingCost: 'Operating cost (€/an)',
        panelPeakPower: 'Total installed power (kW)',
        producedEnergy: 'Produced energy (MWh/an)',
        renMix: 'EnR&R energy mix (%)',
        renRate: 'Global EnR&R rate (%)',
        roofCoverageRate: 'Roof coverage rate (%)',
        selectForCompare: 'Select for comparison',
        storage: 'Storage',
        summerEnergyNeedsRatio: 'Ratio of summer energy need over total need (%)',
        tertiaryEnergyNeedsRatio: 'Ratio of needs related to tertiary sector (%)',
        totalNeeds: 'Total energy needs (MWh/year)',
      },
      compareIterationsButton: 'Compare iterations',
      constraintsWarning: 'Exploitation constraints reached for: ',
      energyMixLeftOver: 'supplement',
      exploitationConstraint: {
        reached: 'Exploitation constraints reached for {name}: ',
        threshold: 'Allowed value: ',
        actual_value: 'Actual value: ',
      },
      filterConstraintsReached:
        'Display only iterations where the exploitation constraints thresholds have not been reached',
      displayedIterationsInChart:
        'The lines in bold correspond to the mixes displayed in the chart.',
    },
    ScenarioNeedsGraph: {
      sectionTitle: 'Focus on needs',
      graph: {
        title: {
          cooling: 'District cooling needs',
          heating: 'District heat needs',
          electric: 'Electricity needs of the zone: {name}',
        },
        abscissa: 'Power',
        ordinate: 'Time',
        legend: {
          cooling: 'Cooling load',
          heating: 'Heating load',
          electric: 'Electricity load',
        },
      },
    },
    ElectricLcoeGraph: {
      sectionTitle: 'LCOE by dimensionning method',
      kpis: {
        autoConsumptionRate: 'Autoconsommation rate: {rate}%',
        autoProductionRate: 'Autoproduction rate: {rate}%',
        daytimeNeedsRatio: '10am to 6pm needs ratio',
        iterationNameWithoutStorage: 'Iteration {index} without storage',
        iterationNameWithStorage: 'Iteration {index} with storage',
        lcoe: 'LCOE: {lcoe}€/MWh',
        residentialNeedsRatio: 'Housing needs ratio',
        roofCoverageRate: 'Roof coverage rate: {rate}%',
        storage: 'Has batteries: {storage}',
        tertiaryNeedsRatio: 'Tertiary needs ratio',
      },
      panelSizing: {
        bestLcoe: 'Best LCOE',
        bestAutoConso: 'Best autoconsumption',
        bestAutoProd: 'Best autoproduction',
      },
      graph: {
        ordinate: 'LCOE',
        abscissa: 'Dimensionning mode',
      },
    },
    ElectricLoadGraph: {
      sectionTitle: 'Hourly electric load of the zone',
      graph: {
        yAxisLegend: 'Energy',
        dataLegend: 'Electricity needs',
      },
    },
    ElectricProductionGraph: {
      sectionTitle: 'Electricity production',
      graph: {
        yAxisLegend: 'Energy',
        dataLegend: 'Electricity production',
      },
    },
  },
  ErrorPage: {
    404: {
      message: 'Page not found',
      text: "This page doesn't exist or has been deleted.\n\nIf you have any problem, please contact ",
    },
    500: {
      message: 'Internal server error',
      text: 'A server side error occured. If the problem continues please contact ',
    },
  },
};

export default en;
